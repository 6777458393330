<template>
  <div class="d-flex justify-center align-center" style="height: 100%;">
    <div class="text-center">
      <div class="headline py-4">Виджет {{ widget.template }} не найден</div>
      <div>Пожалуйста, обратитесь в службу поддержки</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: null, default: () => {} },
    widget: { type: null, default: () => {} }
  }
}
</script>

<style scoped>

</style>
